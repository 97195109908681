<template>
  <div>
    <PoliticasProteccionDatos
      v-on:handleClose="
        dialogSee = false;
      "
      :modal="dialogSee"

    ></PoliticasProteccionDatos>
    <v-navigation-drawer
      app
      v-model="drawer"
      permanent
      expand-on-hover
      class="mx-auto"
      width="320"
    >
      <v-list-item class="px-2">
        <v-avatar
          color="indigo darken-4"
          size="36"
          class="white--text text-h5"
        >{{ avatarLetter }}</v-avatar
        >
        <v-img
          :src="require('@/assets/media/rpg/logo-rgp-login.png')"
          alt="RPG"
        ></v-img>
      </v-list-item>

      <v-divider></v-divider>
      <v-list dense>
        <v-list-item
          key="Home"
          to="/dashboard"
          color="primary"
          active-class="highlighted"
        >
          <v-list-item-icon>
            <v-icon>dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Inicio</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          ref="pescain"
          prepend-icon="looks_one"
          no-action
          v-if=" $can('verPermisionariosIndustriales')
              || $can('verEmbarcacionesIndustriales')
              || $can('verPescadoresIndustriales')
            "
        >
          <template v-slot:activator>
            <v-list-item-content >
              <v-list-item-title>Pesca Industrial</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            to="/companies-ind"
            v-if="$can('verPermisionariosIndustriales')"
            :class="
              current_parent_router == '/companies-ind'
                ? 'v-list-item--active'
                : ''
            "
          >
            <v-list-item-content>
              <v-list-item-title
              >Permisos de Pesca Industrial</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/boats-ind"
            v-if="$can('verEmbarcacionesIndustriales')"
            :class="
              current_parent_router == '/boats-ind' ? 'v-list-item--active' : ''
            "
          >
            <v-list-item-content>
              <v-list-item-title> Embarcaciones Industriales</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/fishermen-ind"
            v-if="$can('verPescadoresIndustriales')"
            :class="
              current_parent_router == '/fishermen-ind'
                ? 'v-list-item--active'
                : ''
            "
          >
            <v-list-item-content>
              <v-list-item-title
              >Registro Pescadores Industriales</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group
          prepend-icon="looks_two"
          ref="pescart"
          v-if="$can('verPescadoresArtesanales') ||
            $can('verListadosConDetalleOrganizaciones') ||
            $can('verEmbarcacionesArtesanal') ||
            $can('verPermisionariosArtesanales')
          "
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content ref="/companies-art">
              <v-list-item-title>Pesca Artesanal</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            to="/fishermen-art"
            v-if="$can('verPescadoresArtesanales')"
            :class="
              current_parent_router == '/fishermen-art'
                ? 'v-list-item--active'
                : ''
            "
          >
            <v-list-item-content>
              <v-list-item-title
              >Registro Pescadores Artesanales</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="$can('verListadosConDetalleOrganizaciones')"
            key="Organizaciones"
            to="/organizations"
            color="primary"
            :class="
              current_parent_router == '/organizations'
                ? 'v-list-item--active'
                : ''
            "
          >
            <v-list-item-content>
              <v-list-item-title>Organizaciones</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            to="/boats-art"
            v-if="$can('verEmbarcacionesArtesanal')"
            :class="
              current_parent_router == '/boats-art' ? 'v-list-item--active' : ''
            "
          >
            <v-list-item-content>
              <v-list-item-title> Embarcaciones Artesanales</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/companies-art"
            v-if="$can('verPermisionariosArtesanales')"
            :class="
              current_parent_router == '/companies-art'
                ? 'v-list-item--active'
                : ''
            "
          >
            <v-list-item-content>
              <v-list-item-title>Permisos de Pesca Artesanal</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group
          prepend-icon="looks_3"
          ref="pescart"
           v-if="$can('verListaPescadoresDeportivos')"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Pesca Deportiva</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            to="/fishermen-dep"
            v-if="$can('verListaPescadoresDeportivos')"
            color="primary"
            :class="
              current_parent_router == '/fishermen-dep'
                ? 'v-list-item--active'
                : ''
            "
          >
            <v-list-item-content>
              <v-list-item-title
              >Registro Pescadores Deportivos</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group
          v-if="$can('verListaPescadoresMonitoreo')"
          prepend-icon="mdi-clipboard-clock"
          ref="pescart"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Monitoreo</v-list-item-title>
            </v-list-item-content>
          </template>

          <!-- Ítems existentes -->
          <v-list-item
            to="/measurement"
            v-if="$can('measurement')"
            color="primary"
            :class="
      current_parent_router == '/measurement'
        ? 'v-list-item--active'
        : ''
    "
          >
            <v-list-item-content>
              <v-list-item-title>Mediciones</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            to="/weightcheck"
            v-if="$can('weightcheck')"
            color="primary"
            :class="
      current_parent_router == '/weightcheck'
        ? 'v-list-item--active'
        : ''
    "
          >
            <v-list-item-content>
              <v-list-item-title>Control de pesos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            to="/activities"
            v-if="$can('activities')"
            color="primary"
            :class="
      current_parent_router == '/activities'
        ? 'v-list-item--active'
        : ''
    "
          >
            <v-list-item-content>
              <v-list-item-title>Actividades</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            to="/landing"
            v-if="$can('landing')"
            color="primary"
            :class="
      current_parent_router == '/landing'
        ? 'v-list-item--active'
        : ''
    "
          >
            <v-list-item-content>
              <v-list-item-title>Desembarco</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Nuevos ítems con enlaces externos -->
          <v-list-item>
            <a href="https://ssayp.rgpsai.org/login" target="_blank" rel="noopener noreferrer" style="text-decoration: none; color: inherit;">
              <v-list-item-content>
                <v-list-item-title>SSAYP</v-list-item-title>
              </v-list-item-content>
            </a>
          </v-list-item>

          <v-list-item>
            <a href="http://159.65.249.154:3838/rgpsai-stock/" target="_blank" style="text-decoration: none; color: inherit;">
              <v-list-item-content>
                <v-list-item-title>Stock Pesquero</v-list-item-title>
              </v-list-item-content>
            </a>
          </v-list-item>

          <v-list-item>
            <a href="http://159.65.249.154:3838/dl-tool" target="_blank" rel="noopener noreferrer" style="text-decoration: none; color: inherit;">
              <v-list-item-content>
                <v-list-item-title>DL - Tools</v-list-item-title>
              </v-list-item-content>
            </a>
          </v-list-item>

          <v-list-item>
            <a href="https://bsnouffer.shinyapps.io/san-andres-dashboard/" target="_blank" rel="noopener noreferrer" style="text-decoration: none; color: inherit;">
              <v-list-item-content>
                <v-list-item-title>Lobster Fishery Management Too</v-list-item-title>
              </v-list-item-content>
            </a>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-if="$can('verScvPesca')"
          key="scv-pesca"
          to="/track-fishing"
          colo="primary"
          :class="
            current_parent_router == '/track-fishing'
              ? 'v-list-item--active'
              : ''
          "
        >

          <v-list-item-icon>
            <v-icon>mdi-crosshairs-gps</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>SCV Pesca</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item
         v-if="$can('verMonitoreo')"
          key="monitoring"
          to="/monitoring"
          color="primary"
          :class="
            current_parent_router == '/monitoring'
              ? 'v-list-item--active'
              : ''
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-clipboard-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Monitoreo</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-list-item
          key="Investigaciones"
          to="/investigation-by-violation-statute"
          color="primary"
          v-if="$can('verListaDeInvestigaciones')"
          :class="
            current_parent_router == '/investigation-by-violation-statute'
              ? 'v-list-item--active'
              : ''
          "
        >
          <v-list-item-icon>
            <v-icon>fas fa-paste</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Investigaciones</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          prepend-icon="youtube_searched_for"
          no-action
          v-if=" $can('verConsultasIndustriales') ||
           $can('verConsultasArtesanales') ||
           $can('verConsultasDeportivas')"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Consultas</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-group sub-group no-action
            v-if="$can('verConsultasIndustriales')"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Libro de Pesca Industrial</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              key="Embarcaciones"
              to="/list-boat-ind"
              color="primary"
            >
              <v-list-item-icon>
                <v-icon>directions_boat</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Registro de Embarcaciones</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
             <v-list-item
            key="Patentes"
              to="/list-patente-ind"
              color="primary"
            >
              <v-list-item-icon>
                <v-icon>directions_boat</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Registro de Patentes</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-group sub-group no-action
            v-if="$can('verConsultasArtesanales')"

          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Libro de Pesca Artesanal</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              key="Embarcaciones"
              to="/list-boat-art"
              color="primary"
            >
              <v-list-item-icon>
                <v-icon>directions_boat</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Registro de Embarcaciones</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              key="Patentes"
              to="/list-patente-art"
              color="primary"
            >
              <v-list-item-icon>
                <v-icon>directions_boat</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Registro de Patentes</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>


          <v-list-group sub-group no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Registro de Pescadores</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-if="$can('verConsultasArtesanales')"
              key="Pescadores-a"
              to="/list-fisherman-art"
              color="primary"
            >
              <v-list-item-icon>
                <v-icon>fas fa-fish</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Pesca Artesanal</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              key="Pescadores-i"
              v-if="$can('verConsultasIndustriales')"
              to="/list-fisherman-ind"
              color="primary"
            >
              <v-list-item-icon>
                <v-icon>fas fa-fish</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Pesca Industrial</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              key="Pescadores-d"
              to="/list-fisherman-dep"
              v-if="$can('verConsultasDeportivas')"
              color="primary"
            >
              <v-list-item-icon>
                <v-icon>fas fa-fish</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Pesca Deportiva</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list-group>

        <v-list-item
          key="Users"
          v-if="$can('verUsuarios')"
          to="/users"
          color="primary"
          :class="
            current_parent_router == '/users' ? 'v-list-item--active' : ''
          "
        >
          <v-list-item-icon>
            <v-icon>supervised_user_circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Usuarios</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="$can('verConfiguraciones')"
          key="configuracion"
          to="/general-config"
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Configuración General</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- APP BAR -->
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      dark
      app
      color="indigo darken-4"
    >
      <v-toolbar-title style="width: 450px" class="ml-12 pl-3">
        RGP SAI
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on"  v-if="$can('verNotificaciones')">
            <v-icon small large>notifications</v-icon>
            <v-badge v-if="notifications_count > 0" :content="notifications_count" color="red" overlap></v-badge>
          </v-btn>
        </template>
        <v-list dense three-line>
          <v-subheader>
            NOTIFICACIONES
          </v-subheader>
          <div style="max-height: 350px; overflow-y: scroll">
            <template v-for="(item, index) in notifications">
              <v-list-item
              :key="`notification_${index}`"
                @click="markAsRead(item)"
                :class="{'indigo lighten-5' : item.pivot.read_at === null}"
              >
                <v-list-item-icon>
                  <v-icon>mdi-alarm</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{item.data}}</v-list-item-title>
                  <v-list-item-subtitle>{{item.created_at | formatAgo}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                :key="index"
                :inset="item.inset"
                v-if="index < notifications.length-1"
              ></v-divider>
            </template>
          </div>
        </v-list>
      </v-menu>

      <v-btn text small @click="handleLogout">
        <v-icon small class="mx-2"> exit_to_app</v-icon>
        Cerrar sesión
      </v-btn>
    </v-app-bar>
    <v-container fluid style="padding-top:0px">
      <v-row :align="alignment" :justify="justify">
        <v-col cols="12" class="white lighten-5">
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-container>
    <v-footer app color="indigo darken-4" class="white--text" >
      <span>ALTOSENTIDO &copy; {{new Date().getFullYear()}}</span>
      <v-spacer></v-spacer>
      <a style="color: #e0e1f0;cursor: pointer;text-decoration: underline;" @click="dialogSee=true"> Políticas y privacidad </a>
    </v-footer>
  </div>
</template>

<script>
import {
  getAuthUserUrl,
  getNotifications,
  getConfig,
  getHeader, basePath
} from "../config";
import avatar from "../assets/media/rpg/favicon-rgp.png";
import Pusher from "pusher-js";
import { notificationTypes } from "../enums/notificationTypes";
import { pusherAppId, pusherCluster } from "../env";
import axios from "axios";
import { consultar } from "../services/variablesconcepto";

export default {
  components: {
    PoliticasProteccionDatos: () => import('./PoliticasProteccionDatos')
  },
  data: () => ({
    dialogSee: false,
    notifications_summary : [],
    dialog: false,
    avatar: avatar,
    drawer: null,
    alignment: "start",
    justify: "center",
    pescain: true,
    pescart: true,
    reads: [],
    unreads: [],

    settings: [],
    mini: true,
    total: 0,
  }),
  computed: {
    username() {
      return JSON.parse(localStorage.getItem("authUser"));
    },
    avatarLetter() {
      const fullName =`${JSON.parse(localStorage.getItem("authUser")).firstname} ${JSON.parse(localStorage.getItem("authUser")).lastname}`;
      const splitName = fullName.split(" ");
      return `${splitName[0].charAt(0)}${splitName?.[1]?.charAt(0)}`;
    },
    notifications (){
      return this.$store.state.notifications;
    },
    current_parent_router() {
      return this.$store.state.active_route;
    },
    notifications_count (){
      return this.notifications.filter(x => x.pivot.read_at === null).length;
    },
    notification_types(){
      return notificationTypes
    }
  },

  methods: {
    groupBy: function (list, keyGetter) {
      const map = new Map();
      list.map((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return map;
    },

    handleLogout() {
      const session_id = JSON.parse(
        localStorage.getItem("authUser")
      ).id;
      this.axios.post(getAuthUserUrl + "?q=" + session_id).then(() => {
        window.localStorage.removeItem("authUser");
        window.location = "/";
      });
    },
    getAllNotifications() {
      this.axios
        .get(getNotifications, { headers: getHeader() })
        .then((response) => {
          this.$store.commit('NOTIFICATIONS' , response.data.notifications);
        });
    },
    subscribe(){
      let pusher = new Pusher(pusherAppId, {
        cluster: pusherCluster,
      });

      let channel = pusher.subscribe("notifications-channel");

      channel.bind("toExpire",  data => {
        this.$store.commit('NOTIFICATIONS' , data.notifications.notifications);
      });

      if (this.username.roles[0]?.name === 'Aprobaciones'){

        channel.bind("fisherman-request-aprove",  (data) => {
          this.$store.commit('PUSH_NOTIFICATION' , data?.notification);
        });

        channel.bind("company-request-aprove",  (data) => {
          this.$store.commit('PUSH_NOTIFICATION' , data?.notification);
        });

      }

    },
    markAsRead(item){
      let index = this.notifications.findIndex(x => x.id === item.id);
      if(this.notifications[index].pivot.read_at === null) axios.get(`${basePath}notifications/mark-as-read/${item.id}`, {headers: getHeader()});
      this.$store.commit('NOTIFICATION_READ' , item.id);

      const dataText = this.notifications[index].data;
      const boatNameMatch = dataText.match(/La embarcación ([^-]+) -/);

      const boatName = boatNameMatch ? boatNameMatch[1].trim() : 'Nombre desconocido';

      this.$router.push({
        name: 'trackFishingNotification',
        query: { boat_name: boatName }
      });

    },
  },
  beforeMount() {
    if (this.username.roles[0]?.name === 'Super Administrador') {
      this.axios.get(getConfig, { headers: getHeader() }).then((response) => {
        this.$store.commit("SETTINGS", response.data);
        this.settings = response.data;
      });
    }
    this.getAllNotifications();
  },
  created(){
    this.subscribe();
    consultar.fetch();
  },
};
</script>

<style lang="scss" scoped>
.back {
  background-image: url("./../assets/media/rpg/bg-base.png");
  background-size: 100% 100%;
}


</style>
