//export const apiDomain = "https://backendpruebas.rgpsai.org/";
export const apiVersion = "v1/";
export const apiDomain =process.env.VUE_APP_API_DOMAIN;
export const baseUrl = "api/rpg-sai/";
export const basePath = apiDomain + baseUrl + apiVersion;

export const loginUrl = apiDomain + "oauth/token";
export const getAuthUserUrl = apiDomain + "api/rpg-sai/v1/auth-user";
export const LogoutSession = apiDomain + "api/logout-session";
export const forgotPasswordUrl = apiDomain + "api/forgot-password";
export const resetPasswordUrl = apiDomain + "api/reset-password";

//Users
export const getAllUsersUrl = apiDomain + "api/rpg-sai/v1/all-users";
export const getAllUsersActivityUrl =
  apiDomain + "api/rpg-sai/v1/all-users-activity";
export const createUser = apiDomain + "api/rpg-sai/v1/users/create";

// Roles
export const getAllRoles = apiDomain + "api/rpg-sai/v1/roles-user";
//User
export const UserDesactive = apiDomain + "api/rpg-sai/v1/user-desactive";
export const UserActive =  apiDomain + "api/rpg-sai/v1/user-active";
//Fisherman
export const photoFishermanUrl = apiDomain + "api/rpg-sai/v1/photo-fisherman/";
export const firmaUrl = apiDomain + "api/rpg-sai/v1/firma/";
export const boatImageUrl = apiDomain + "api/rpg-sai/v1/boat_images/";
export const editBoatUrl = apiDomain + "api/rpg-sai/v1/edit_boat/";

export const getAllFishermen = apiDomain + "api/rpg-sai/v1/all-fishermen";
export const getAllRequestFishermen =
  apiDomain + "api/rpg-sai/v1/all-requests-fishermen";
export const findRequestFishermen = apiDomain + "api/rpg-sai/v1/find/request";
export const getAllRequestByFisherman =
  apiDomain + "api/rpg-sai/v1/all-requests";
export const getAllFishermenArt =
  apiDomain + "api/rpg-sai/v1/all-fishermen-art";
export const getAllFishermenInd =
  apiDomain + "api/rpg-sai/v1/all-fishermen-ind";
export const getAllFishermenDep =
  apiDomain + "api/rpg-sai/v1/all-fishermen-dep";

export const savePeticion = apiDomain + "api/rpg-sai/v1/save-peticion";
export const checkPermit = apiDomain + "api/rpg-sai/v1/check-permission";
export const checkBoat = apiDomain + "api/rpg-sai/v1/check-boat";

export const GestionarPeticion =
  apiDomain + "api/rpg-sai/v1/gestionar-peticion";
export const saveFishermanUrl = apiDomain + "api/rpg-sai/v1/save-fisherman";
export const getFishermenQuery = apiDomain + "api/rpg-sai/v1/all-fisherman";
export const getFisherman = apiDomain + "api/rpg-sai/v1/pescador";
export const getRequestFishermen =
  apiDomain + "api/rpg-sai/v1/solicitud-pescador";
//tortas
/*
 * Pescadores San Andrés/ Total de pescadores Pescadores Providencia y Santa Catalina/ Total de pescadores"
 */
export const getFishermanLugar = apiDomain + "api/rpg-sai/v1/fisherman-lugar";
// Pescadores asociados vs pescadores independientes
export const getFishermanAsoVsIndep =
  apiDomain + "api/rpg-sai/v1/fisherman-organization-cooperative";
// Pescadores por tipo de pesquería
export const getFishermanTypeFisheryUrl =
  apiDomain + "api/rpg-sai/v1/fisherman-type-fishery";
// Pescadores por arte de pesca
export const getFishermanArtsUrl = apiDomain + "api/rpg-sai/v1/fisherman-arts";
// Pescadores/ asociación
export const getFishermanOrganizationNameUrl =
  apiDomain + "api/rpg-sai/v1/fisherman-organization-name";

//Company
export const getAllCompaniesUrl = apiDomain + "api/rpg-sai/v1/all-companies";
export const getAllRequestCompanies = apiDomain + "api/rpg-sai/v1/request-company/all";
export const getCompanyUrl = apiDomain + "api/rpg-sai/v1/company";
export const findRequestCompanyUrl = apiDomain + "api/rpg-sai/v1/request-by-company";
export const saveCompanyUrl = apiDomain + "api/rpg-sai/v1/save-company";
export const saveCompanyInvUrl =
  apiDomain + "api/rpg-sai/v1/save-company-investigation";
export const saveFishermenInvUrl =
  apiDomain + "api/rpg-sai/v1/save-fishermen-investigation";

export const getPermissionIndustrialByCompanyUrl =
  apiDomain + "api/rpg-sai/v1/get-permission-in-by-company";
export const getPermissionArtesanalByCompanyUrl =
  apiDomain + "api/rpg-sai/v1/get-permission-art-by-company";
export const getPermissionUrl = apiDomain + "api/rpg-sai/v1/get-permission";
export const getCompaniesIndUrl =
  apiDomain + "api/rpg-sai/v1/all-companies-industrials";
export const saveRadicado = apiDomain + "api/rpg-sai/v1/save-radicado";

//Permission
export const savePermissionUrl = apiDomain + "api/rpg-sai/v1/save-permission";
export const saveFirstPermissionUrl = apiDomain + "api/rpg-sai/v1/save-first-permission";
export const savePermissionArtesanalUrl =
  apiDomain + "api/rpg-sai/v1/save-permission-art";

//Boats
export const getBoatsByCompanyUrl =
  apiDomain + "api/rpg-sai/v1/get-boats-by-company";
export const getBoatsUrl = apiDomain + "api/rpg-sai/v1/get-boats-consecutive";
export const saveBoatUrl = apiDomain + "api/rpg-sai/v1/save-boat";
export const saveBoatParcialUrl = apiDomain + "api/rpg-sai/v1/save-boat-parcial";
export const saveBoatViolationStatuteInUrl =
  apiDomain + "api/rpg-sai/v1/save-boat-statute-violation-in";
export const saveBoatViolationStatuteArtUrl =
  apiDomain + "api/rpg-sai/v1/save-boat-statute-violation-art";
export const allBoatsPath = apiDomain + "api/rpg-sai/v1/all-boats";
export const allPatentesPath = apiDomain + "api/rpg-sai/v1/all-patentes";
export const unlinkedBoats = apiDomain + "api/rpg-sai/v1/unlinked-boats";
export const findRequestBoat= apiDomain + "api/rpg-sai/v1/boat/find/request";
export const getBoatPath = basePath + "boat/"

//tortas
/*
 * No. de Barcos / Zona de pesca autorizada
 */
export const getBoatZoneAutorizedUrl =
  apiDomain + "api/rpg-sai/v1/barcos-zona-pesca-autorizada";
/* No. de barcos / Puerto de desembarco autorizado en Colombia */
export const getBoatPortLandingUrl =
  apiDomain + "api/rpg-sai/v1/barcos-puerto-desembarco";
export const getBoatPortLandingArtUrl =
  apiDomain + "api/rpg-sai/v1/barcos-puerto-desembarco-art";
/* No. de barcos / Arte de pesca */
export const getBoatArtFisherUrl =
  apiDomain + "api/rpg-sai/v1/barcos-arte-pesca";
export const getBoatArtFisherArtUrl =
  apiDomain + "api/rpg-sai/v1/barcos-arte-pesca-art";
/* No. de barcos / Arte de pesca */
export const getBoatfisherAutorizedUrl =
  apiDomain + "api/rpg-sai/v1/barcos-pesqueria-autorizada";
export const getBoatfisherAutorizedArtUrl =
  apiDomain + "api/rpg-sai/v1/barcos-pesqueria-autorizada-art";

export const findAuthorizedSpecie =
  apiDomain + "api/rpg-sai/v1/permisos/buscar-especie-autorizada";

export const getBoatIndUrl = apiDomain + "api/rpg-sai/v1/all-boats-industrials";
export const getBoatArtUrl = apiDomain + "api/rpg-sai/v1/all-boats-artesanals";

//Technical Inspection
export const getAllTechnicalInspectionInUrl =
  apiDomain + "api/rpg-sai/v1/all-technical-inspection-in";
export const saveTechnicalInspectionUrl =
  apiDomain + "api/rpg-sai/v1/save-technical-inspection-in";
export const getAllTechnicalInspectionArtUrl =
  apiDomain + "api/rpg-sai/v1/all-technical-inspection-art";
export const saveTechnicalInspectionArtUrl =
  apiDomain + "api/rpg-sai/v1/save-technical-inspection-art";

export const getTechnicalInspectionArt =
  apiDomain + "api/rpg-sai/v1/inspeccion-tecnica-artesanal";

export const getTechnicalInspectionInd =
  apiDomain + "api/rpg-sai/v1/inspeccion-tecnica-industrial";

export const getBoatsTrack =
  apiDomain + "api/rpg-sai/v1/track/boats";

export const getTrack =
  apiDomain + "api/rpg-sai/v1/track";

//Concept Technical
export const getAllConceptTechnicalUrl =
  apiDomain + "api/rpg-sai/v1/all-concept-technical-in";
export const saveConceptTechnicalUrl =
  apiDomain + "api/rpg-sai/v1/save-concept-technical";
export const saveRequestCompanyUrl =
  apiDomain + "api/rpg-sai/v1/save-new-request-company";
export const getCompanyByNit = apiDomain + "api/rpg-sai/v1/get-company";
export const getCompany =
  apiDomain + "api/rpg-sai/v1/get-company/investigation";
export const OpenInvestigation =
  apiDomain + "api/rpg-sai/v1/open-investigation";

export const ResolvRequest =
  apiDomain + "api/rpg-sai/v1/resolv-request-company";

export const getAllConceptTechnicalArtUrl =
  apiDomain + "api/rpg-sai/v1/all-concept-technical-art";
export const saveConceptTechnicalArtlUrl =
  apiDomain + "api/rpg-sai/v1/save-concept-technical-art";

export const getConceptTechnicalArt =
  apiDomain + "api/rpg-sai/v1/concepto-tecnico-artesanal";

export const getConceptTechnicalArtPatent =
  apiDomain + "api/rpg-sai/v1/concepto-tecnico-artesanal-last-patent";

export const getConceptTechnicalInd =
  apiDomain + "api/rpg-sai/v1/concepto-tecnico-industrial";

//Statute Violation
// export const getAllStatutesViolationInUrl =
//   apiDomain + "api/rpg-sai/v1/all-statutes-violations-in";
export const getAllStatutesViolationArtUrl =
  apiDomain + "api/rpg-sai/v1/all-statutes-violations-art";
export const saveStatuteViolationInUrl =
  apiDomain + "api/rpg-sai/v1/save-statute-violation-in";
export const saveStatuteViolationUrl =
  apiDomain + "api/rpg-sai/v1/save-statute-violation";

export const getAllStatutesViolationInUrl =
  apiDomain + "api/rpg-sai/v1/estatutos-violacion";

export const getStatutesViolationInUrl =
  apiDomain + "api/rpg-sai/v1/get-estatuto";
export const getStatuteViolationInd =
  apiDomain + "api/rpg-sai/v1/estatuto-violacion-industrial";

//Certificate Patent
export const getAllCertificatePatentUrl =
  apiDomain + "api/rpg-sai/v1/all-certificate-patent";
export const getAllCertificatePatentArtUrl =
  apiDomain + "api/rpg-sai/v1/all-certificate-patent-art";
export const saveCertificatePatentIndUrl =
  apiDomain + "api/rpg-sai/v1/save-certificate-patent-ind";
export const saveCertificatePatentArtUrl =
  apiDomain + "api/rpg-sai/v1/save-certificate-patent-art";

export const getCertificatePatentArt =
  apiDomain + "api/rpg-sai/v1/certificado-patente-artesanal";

export const getCertificatePatentInd =
  apiDomain + "api/rpg-sai/v1/certificado-patente-industrial";
export const getSMV = apiDomain + "api/rpg-sai/v1/smv";

// INICIO

export const getNotifications = apiDomain + "api/rpg-sai/v1/notifications";
export const getConfig = apiDomain + "api/rpg-sai/v1/items-config";

export const getPortsLanding = apiDomain + "api/rpg-sai/v1/portOfLanding";


//Trazabilidad
export const getActivityLog = apiDomain + "api/rpg-sai/v1/getTrazabilidad";

export const ReadNotifications =
  apiDomain + "api/rpg-sai/v1/read-notifications";
export const saveSetting = apiDomain + "api/rpg-sai/v1/save-setting";
export const getHomeInformation = apiDomain + "api/rpg-sai/v1/consulta/inicio";
export const getHeader = () => {
  const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
  const headers = {
    Accept: "application/json",
    Authorization: "Bearer " + tokenData?.access_token,
  };
  return headers;
};

//Skylight
export const skylightEvents = apiDomain + "api/rpg-sai/v1/events-boat";
export const skylightEventsHistory = apiDomain + "api/rpg-sai/v1/events-boat-history";

export const allVesselEventData = apiDomain + "api/track-fishing-events";

export const vesselTrackingAndHistory = apiDomain + "api/track-tracking-history-events";

export const allBoatSearch = apiDomain + "api/all-boats-search";

export const updateState = (id) => `${apiDomain}api/rpg-sai/v1/request-fisherman/${id}/update-state`;

export const getRendezvousInline = apiDomain + "api/rendezvous-inline";

export const eventsQueryByArea = apiDomain +"api/events-by-area";

export const areaOfInterest = apiDomain + "api/register-area";

export const allAreaOfInterest = apiDomain + "api/all-areas";

export const consultationByBoat = apiDomain + "api/boat-query";

export const allBoatsQuery = apiDomain + "api/all-boats-query";




