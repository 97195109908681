<template>
  <v-app>
    <v-main :style="mainStyle">
      <v-container fluid class="ma-0 pa-0" text-xs-center>
        <router-view></router-view>
        <loader></loader>
      </v-container>
    </v-main>
    <v-snackbar v-model="snackbar" top="top" color="primary">
      Hubo un error en el llamado al servidor
      <v-btn dark text @click="snackbar = false"> Cerrar </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import Loader from "./components/Loader.vue";

export default {
  name: "App",

  components: { Loader },

  created() {
    this.axios.interceptors.request.use(
      (config) => {
        if (!config.params?.search) {
        //  this.$store.commit("START_LOADER");
         this.$store.commit("LOADER", true);

        }
        return config;
      },
      (error) => {
        // Do something with request error
      //  this.$store.commit("STOP_LOADER");
        this.$store.commit("LOADER", false);

        return Promise.reject(error);
      }
    );
    this.axios.interceptors.response.use(
      (response) => {
        if (this.$store.state.loader) {
          //this.$store.commit("STOP_LOADER");
          this.$store.commit("LOADER", false);
        }
        return response;
      },
      (err) => {
        if (err.response?.status == 422) {
          const obj_erros = Object.values(err.response?.data.errors);

          obj_erros.forEach((err) => {
            this.$toasted.show(`Error ${Array.isArray(err) ? err[0] : err}`, {
              theme: "bubble",
              position: "bottom-right",
              duration: 4000,
              action: {
                text: "",
                onClick: (e, toastObject) => {
                  toastObject.goAway(0);
                },
              },
            });
          });
        } else if (err.response?.status == 500) {
          this.snackbar = true;
        } else if (err.response?.status == 401) {
          window.localStorage.removeItem("authUser");
          this.$router.push({name: '["login"]'});
        }

       // this.$store.commit("STOP_LOADER");
        this.$store.commit("LOADER", false);

        throw err;
      }
    );
  },
  computed :{
    mainStyle() {
      return this.$route.path === "/" ? "" : "padding: 64px";
    },
  },
  data: () => ({
    snackbar: false,
    Routes: ["technical-inspection-hm"["new-technical-inspection-hm"]],
  }),
};
</script>
<style>
 .toast-rgp, .toasted {
   font-family: "Roboto", sans-serif;
 }
 .swal-text, .swal-button {
   font-family: "Roboto", sans-serif;
 }
 .card-drag {
   padding: 8px;
 }
</style>